ion-button {
  text-transform: none !important;
  font-weight: 600 !important;
  width: max-content;
  --box-shadow: none;
}

ion-fab-button.small {
  height: 40px;
  width: 40px;
}

ion-accordion {
  border-radius: 20px;
}

ion-card {
  border-radius: 20px;
  ion-card-title {
    text-transform: none !important;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  ion-card-subtitle {
    text-transform: none !important;
    font-size: 1rem;

    &.small-subtitle {
      font-size: 0.7rem;
    }
  }
}

ion-toast {
  font-size: 1.1rem;
  line-height: 1.3rem;
}

.alert-button.danger {
  color: var(--ion-color-danger) !important;
}

ion-toast.danger {
  color: var(--ion-color-dark) !important;
  --button-color: var(--ion-color-danger) !important;
}

ion-toast.success {
  color: var(--ion-color-dark) !important;
  --button-color: var(--ion-color-success) !important;
}

ion-modal {
  backdrop-filter: blur(2px);
  &::part(backdrop) {
    background: #000;
  }
  &::part(content) {
    border-radius: 20px;
    box-shadow: 0px 0px 20px 5px #00000020;
  }
}

.alert-wrapper {
  border-radius: 20px !important;
}

ion-popover {
  // --backdrop-opacity: 1;
  backdrop-filter: blur(2px);
  &::part(backdrop) {
    background: #000;
  }

  &::part(content) {
    border-radius: 20px;
    // box-shadow: 0px 0px 20px 5px #00000020;
  }
  ion-item {
    border-bottom: 1px solid #00000020;
  }
  ion-fab-button {
    --box-shadow: none;
    --background: transparent;
  }

  &.date-picker-popover {
    --width: 320px;
  }
}

ion-content {
  &.action-sheet-body {
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 5px;
  font-weight: bold;
  line-height: 1.5;
  font-family: var(--ion-font-family);
}

ion-header h1,
ion-header h2,
ion-header h3,
ion-header h4,
ion-header h5,
ion-header h6,
ion-card-title {
  font-family: var(--ion-font-family);
}

ion-card-subtitle,
ion-card-content,
p,
li {
  font-family: var(--ion-font-family-second);
}

:root {
  .elevated-card {
    box-shadow: 0px 0px 10px #00000025;
    border: 0px solid transparent;
  }
}

body[data-theme='dark'],
.ios body[data-theme='dark'] {
  .elevated-card {
    box-shadow: 0px 0px 15px #00000025;
    border: 0px solid transparent;
  }

  .gradient-blur {
    opacity: 0.4;
  }
}
