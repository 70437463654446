/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'katex/dist/katex.css';
@import './theme/layouts.scss';
@import './theme/overrides.scss';

@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/keyboard';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import 'swiper/scss/zoom';
@import '@ionic/angular/css/ionic-swiper';

// xs	0
// sm	576px
// md	768px
// lg	992px
// xl	1200px

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  margin: 3px !important;
}
::-webkit-scrollbar-track {
  margin: 20px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: var(--ion-color-light-shade);
  border: 1px solid var(--ion-color-light-tint);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary);
}

*:-webkit-autofill,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus,
*:-webkit-autofill:active {
  /* use animation hack, if you have hard styled input */
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
  /* if input has one color, and didn't have bg-image use shadow */
  -webkit-box-shadow: 0 0 0 1000px var(--ion-color-light-shade) inset;
  /* text color */
  -webkit-text-fill-color: var(--ion-color-dark);
  /* font weigth */
  font-weight: 400 !important;
  color: var(--ion-color-dark);
}

.item-form-field {
  ion-item {
    --background: transparent;
    margin-bottom: 5px;
  }
  .input-error {
    margin-bottom: 10px;
    color: var(--ion-color-danger);
    text-align: end;
  }
}

.question-card {
  border-radius: 20px;
  ion-accordion.main-card {
    box-shadow: none;
    background-color: var(--ion-background-color);
    border: 1px solid #67676730;

    ion-list {
      background: transparent;
    }

    ion-item {
      --background: transparent;
    }
  }

  ion-accordion.nested-accordion {
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;

    ion-list {
      background: transparent;
    }

    ion-item {
      --background: transparent;
    }

    .title {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 1rem 0rem 0rem 0rem;

      &:before {
        content: '';
        display: inline-block;
        margin-right: 5px;
        margin-left: 5px;
        height: 15px;
        width: 5px;
        border-radius: 5px;
        background-color: var(--ion-color-tertiary);
      }
    }

    .info {
      margin: 5px;
      padding: 0px 10px;
    }

    .chip-list {
      display: flex;
      flex-wrap: wrap;
      padding: 5px;
      gap: 5px;
      font-family: var(--ion-font-family-second);

      .question-tag-chip {
        margin: 2px 5px;
        padding: 3px 10px;
        background-color: #80808050;
        border-radius: 1rem;
      }

      .easy {
        color: var(--ion-color-success);
      }

      .medium {
        color: var(--ion-color-warning);
      }

      .hard {
        color: var(--ion-color-danger);
      }
    }
  }

  .answered {
    border: 1px solid var(--ion-color-primary);
    transition: all 0.3s ease-in;
  }

  .title-line {
    margin: 0px 0px 0px 5px;
    min-width: 30px;
    &:after {
      content: '';
      display: inline-block;
      height: 0.2rem;
      width: 0.2rem;
      margin-left: 3px;
      margin-right: 5px;
      background: var(--ion-color-primary);
      border-radius: 2px;
    }
  }

  &.elevated-card {
    .main-card {
      border: 0px solid transparent;
    }
  }
}

.stack-images {
  margin: 0px 0px 0px 5px;
  direction: rtl;
  text-align: left;
  padding-left: 25px;
  min-width: 50px;

  span {
    .stack-image-item {
      height: 35px !important;
      width: 35px !important;
      border-radius: 50%;
      position: relative;
      left: -5px;
      margin-left: -15px;
      border: 1px solid lightgray;
      box-shadow: 5px 0px 5px #00000020;
      cursor: pointer;
      object-fit: cover;
      background-color: lightgray;

      &:hover {
        transform: scale(1.1);
        transition: all 0.5s ease;
        z-index: 99;
      }

      &.small {
        height: 25px !important;
        width: 25px !important;
      }

      &.single {
        margin-left: -5px;
      }

      &.un-selected {
        filter: grayscale(100%);
      }
    }
  }
}

.item-card {
  transition: all 0.3s ease;
  border: 1px solid #67676760;
  box-shadow: none;
  position: relative;
  border-radius: 20px !important;
  overflow: hidden;

  ion-card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: normal;
  }

  ion-card-subtitle {
    margin-top: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: normal;
  }

  ion-card-content {
    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      -webkit-box-orient: vertical;
      word-break: normal;
    }
  }

  .corner-image {
    position: absolute;
    bottom: 5px;
    right: 0px;
  }

  &.grid-card {
    min-height: 170px;
  }

  &.list-card {
    margin-top: -1px;
    margin-bottom: -3px;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      ion-card-hearder {
        overflow: hidden;
      }

      ion-card-content {
        display: flex;
        flex-direction: row;
        row-gap: 10px;
      }
    }

    .stack-images:has(.single) {
      padding-left: 5px !important;
      min-width: 30px !important;
    }
  }
}

.analytics {
  margin-top: 10px;

  .value {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: var(--ion-color-dark);
    font-size: 1rem;
    font-weight: 800;
    padding-right: 7px;

    .line-primary {
      background: var(--ion-color-primary-tint);
    }
    .line-secondary {
      background: var(--ion-color-secondary-tint);
    }
    .line-tertiary {
      background: var(--ion-color-tertiary-tint);
    }

    .line-success {
      background: var(--ion-color-success);
    }

    .line-primary,
    .line-secondary,
    .line-tertiary,
    .line-success {
      height: 1.2rem;
      width: 4px;
      margin-right: 7px;
      border-radius: 3px;
    }

    &.large {
      font-size: 1.5rem;
    }
    &.value-primary {
      color: var(--ion-color-primary);
    }
  }

  .label {
    color: grey;
    padding-right: 10px;
    font-size: 0.7rem;
  }
}

.common-content {
  min-height: 80vh;
}

.template-content-medium {
  padding: 10px;

  &.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  p {
    white-space: pre-line;
  }

  .content-assets {
    ion-icon {
      height: 5rem;
      width: 5rem;
    }
    img {
      max-height: 150px;
      max-width: 150px;
      object-fit: contain;
    }
  }
}

.template-content-small {
  padding: 10px;

  &.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  p {
    white-space: pre-line;
  }
}

.template-content {
  min-height: 60vh;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content-assets {
    position: relative;
    .main-icon {
      font-size: 10rem;
      --ionicon-stroke-width: 3px;
    }

    .sub-icon {
      position: absolute;
      font-size: 1.5rem;
      bottom: 1rem;
      right: 0.5rem;
      padding: 10px;
      border-radius: 50%;
      background-color: var(--ion-background-color);
      box-shadow: 0px 0px 5px #00000040 inset;
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;

    h1 {
      margin: 1rem 0rem 0rem 0rem;
      font-weight: 600;
    }
    p {
      margin: 5px;
      color: grey;
    }
  }

  .gradient-blur {
    position: relative;
    height: 50px;
    width: 150px;
    transform: translate(3rem, -2rem);
    z-index: -1;

    .primary {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      background-color: var(--ion-color-primary);
      filter: blur(4rem);
      transform: translate(-2rem, 0rem);
    }
    .warning {
      position: absolute;
      left: 5rem;
      top: 5rem;
      height: 3rem;
      width: 3rem;
      background-color: var(--ion-color-warning);
      filter: blur(3rem);
    }

    .tertiary {
      position: absolute;
      right: 0rem;
      top: 0rem;
      height: 3rem;
      width: 3rem;
      background-color: var(--ion-color-tertiary);
      filter: blur(1.5rem);
    }
  }
}

.dot-chip-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .chip {
    margin-right: 10px;
    &::before {
      content: '';
      display: inline-block;
      height: 3px;
      width: 3px;
      background-color: var(--ion-color-tertiary);
    }
  }
}

.tag-list {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .tag {
    &::before {
      content: '';
      height: 3px;
      width: 3px;
      border-radius: 2px;
      display: inline-block;
      background-color: var(--ion-color-tertiary);
      margin-right: 5px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.rating {
  display: flex;
  .star {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin: 3px;
    border: 1px solid var(--ion-color-medium);

    &.filled {
      background-color: var(--ion-color-success);
      box-shadow: 0px 5px 7px -2px var(--ion-color-success);
      border: none;
      &._1 {
        background-color: var(--ion-color-warning);
        box-shadow: 0px 5px 7px -2px var(--ion-color-warning);
      }

      &._2 {
        background-color: var(--ion-color-tertiary);
        box-shadow: 0px 5px 7px -2px var(--ion-color-tertiary);
      }

      &._3 {
        background-color: var(--ion-color-secondary);
        box-shadow: 0px 5px 7px -2px var(--ion-color-secondary);
      }

      &._4 {
        background-color: var(--ion-color-primary);
        box-shadow: 0px 5px 7px -2px var(--ion-color-primary);
      }
    }
  }
}

.action-row {
  display: flex;
  gap: 10px;
  ion-fab-button {
    border-radius: 50%;
    --background: transparent;
    --box-shadow: none;

    &.secondary {
      background-color: #1fb8fb30;
      ion-icon {
        color: #1fb8fb !important;
      }
    }

    &.tertiary {
      background-color: #ffa08930;
      ion-icon {
        color: #ffa089 !important;
      }
    }

    &.primary {
      background-color: #7c4dff15;
      ion-icon {
        color: #7c4dff !important;
      }
    }

    &.danger {
      background-color: #ff174415;
      ion-icon {
        color: #ff1744 !important;
      }
    }

    &.warning {
      background-color: #ffc10715;
      ion-icon {
        color: #ffc107 !important;
      }
    }
  }
}

.stacked-list {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;

  .item,
  .course {
    height: 3rem;
    padding: 0.5rem 1rem 0.5rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 2rem;
    border: 1px solid #55555580;
    font-size: 1rem;
    font-weight: 600;
    color: grey;
    transition: all 300ms ease;

    img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0px 5px 10px #00000040;
    }

    &.selected {
      background-color: var(--ion-color-dark);
      color: var(--ion-color-light);
      box-shadow: 0px 0px 10px #00000020;
    }

    &.small {
      height: 1.7rem;
      padding: 0.25rem 0.5rem 0.25rem 0rem;
      font-size: 0.7rem;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

.placeholder-image-container {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 5px;

  &.bcs {
    background-color: #fbab7e;
    background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
  }
  &.med {
    background-color: #4158d0;
    background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
  }
}

.placeholder-image {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 8px;
  font-weight: 800;
  line-height: 30px;
}

@media only screen and (max-width: 1080px) {
  ion-col {
    ion-card {
      margin-inline: 0px;
      margin: 2px;
    }
  }
  .item-card.grid-card {
    margin: 2px;
  }
}

@media only screen and (max-width: 991px) {
  ion-col {
    ion-card {
      margin-inline: 0px;

      ion-card-header,
      ion-content {
        padding: 0.8rem;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  ion-grid {
    max-width: 1080px !important;
    margin-left: auto !important;
  }
}
