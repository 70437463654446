.page-padding {
  margin: 90px 10px 10px 10px;
  display: flex;
  align-items: center;
  height: 30px;

  &.single-page {
    margin: 0px;
    padding: 25px 10px;
  }

  ion-fab-button {
    --box-shadow: none;
    margin-inline: 0px;
  }

  h1 {
    margin: 0px 0px 0px 10px;
    font-weight: 800;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: normal;
  }

  .title-line {
    &:before {
      content: '';
      display: inline-block;
      height: 5px;
      width: 5px;
      border-radius: 5px;
      background: var(--ion-color-primary);
    }
  }

  @media only screen and (max-width: 994px) {
    h1 {
      font-size: 1.2rem;
    }
  }
}

.top-bar {
  padding-left: 10px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-side,
  .right-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.5s ease;
    margin-right: 10px;
  }

  .center {
    display: flex;
    justify-content: flex-end;
  }

  ion-fab-button {
    --box-shadow: none;
    margin-inline: 0px;
  }

  ion-searchbar {
    max-width: 350px;
    min-width: 250px;
    --border-radius: 2rem;
    --box-shadow: 0px 0px 2px #00000020;
    --background: var(--ion-card-background);
  }
}

.top-bar-stick {
  position: sticky;
  top: 55px;
  z-index: 999;
  background-color: var(--ion-background-color);
  box-shadow: 0px 10px 25px -15px #00000050;
  transition: all 0.3s ease-in;
}

.scroll-up-btn {
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 99;
}

.pagination {
  position: fixed;
  bottom: 0px;
  right: 0px;
  min-width: 150px;
  border-radius: 15px;
  z-index: 999;
  box-shadow: 0px 0px 15px #00000030;
}
