// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

// $mat-deep-purple, A200 | 124, 77, 255
$primary: #7c4dff;
$primary-tint: #b47cff;
$primary-shade: #3f1dcb;

// $mat-light-blue, 700 | 2, 136, 209
$secondary: #0288d1;
$secondary-tint: #5eb8ff;
$secondary-shade: #005b9f;

// $mat-red, A100 | 255, 138, 128
$tertiary: #ff8a80;
$tertiary-tint: #ffbcaf;
$tertiary-shade: #c85a54;

// $mat-red, A400 | 255, 193, 7
$warn: #ffc107;
$warn-tint: #fff350;
$warn-shade: #ffc400;

// $mat-red, A400 | 255, 23, 68
$danger: #ff1744;
$danger-tint: #ff616f;
$danger-shade: #c4001d;

// $mat-teal, A700 | 0, 191, 165
$success: #00bfa5;
$success-tint: #5df296;
$success-shade: #008e76;

// FONT FAMILY
$font-family-base: 'Nunito', sans-serif;
$font-family-second: 'Alegreya Sans', sans-serif;

$background-color-opac-light: #ffffff20;
$background-color-opac-dark: #00000020;

:root {
  --ion-font-family: #{$font-family-base};
  --ion-font-family-second: #{$font-family-second};

  --ion-background-color-opac: #{$background-color-opac-light};
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-item-background: #ffffff;
  --ion-toolbar-background: #ffffff;
  --ion-tab-bar-background: #ffffff;
  --ion-card-background: #ffffff;

  /** primary **/
  --ion-color-primary: #{$primary};
  --ion-color-primary-rgb: 124, 77, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #{$primary-shade};
  --ion-color-primary-tint: #{$primary-tint};

  /** secondary **/
  --ion-color-secondary: #{$secondary};
  --ion-color-secondary-rgb: 2, 136, 209;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #{$secondary-shade};
  --ion-color-secondary-tint: #{$secondary-tint};

  /** tertiary **/
  --ion-color-tertiary: #{$tertiary};
  --ion-color-tertiary-rgb: 255, 138, 128;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #{$tertiary-shade};
  --ion-color-tertiary-tint: #{$tertiary-tint};

  /** success **/
  --ion-color-success: #{$success};
  --ion-color-success-rgb: 0, 191, 165;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #{$success-shade};
  --ion-color-success-tint: #{$success-tint};

  /** warning **/
  --ion-color-warning: #{$warn};
  --ion-color-warning-rgb: 255, 193, 7;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #{$warn-shade};
  --ion-color-warning-tint: #{$warn-tint};

  /** danger **/
  --ion-color-danger: #{$danger};
  --ion-color-danger-rgb: 255, 23, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #{$danger-shade};
  --ion-color-danger-tint: #{$danger-tint};

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */

body[data-theme='dark'] {
  --ion-font-family: #{$font-family-base};
  --ion-font-family-second: #{$font-family-second};

  /** primary **/
  --ion-color-primary: #{$primary};
  --ion-color-primary-rgb: 124, 77, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #{$primary-shade};
  --ion-color-primary-tint: #{$primary-tint};

  /** secondary **/
  --ion-color-secondary: #{$secondary};
  --ion-color-secondary-rgb: 2, 136, 209;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #{$secondary-shade};
  --ion-color-secondary-tint: #{$secondary-tint};

  /** tertiary **/
  --ion-color-tertiary: #{$tertiary};
  --ion-color-tertiary-rgb: 255, 138, 128;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #{$tertiary-shade};
  --ion-color-tertiary-tint: #{$tertiary-tint};

  /** success **/
  --ion-color-success: #{$success};
  --ion-color-success-rgb: 0, 191, 165;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #{$success-shade};
  --ion-color-success-tint: #{$success-tint};

  /** warning **/
  --ion-color-warning: #{$warn};
  --ion-color-warning-rgb: 255, 193, 7;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #{$warn-shade};
  --ion-color-warning-tint: #{$warn-tint};

  /** danger **/
  --ion-color-danger: #{$danger};
  --ion-color-danger-rgb: 255, 23, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #{$danger-shade};
  --ion-color-danger-tint: #{$danger-tint};

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e1e1e;
  --ion-color-light-tint: #383a3e;
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body[data-theme='dark'] {
  --ion-background-color-opac: #{$background-color-opac-dark};;
  --ion-background-color: #232323;
  --ion-background-color-rgb: 35, 35, 35;
  --ion-item-background: #2c2c2c;
  --ion-toolbar-background: #2c2c2c;
  --ion-tab-bar-background: #2c2c2c;
  --ion-card-background: #2c2c2c;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body[data-theme='dark'] {
  --ion-background-color-opac: #{$background-color-opac-dark};;
  --ion-background-color: #232323;
  --ion-background-color-rgb: 35, 35, 35;
  --ion-toolbar-background: #2c2c2c;
  --ion-item-background: #2c2c2c;
  --ion-tab-bar-background: #2c2c2c;
  --ion-card-background: #2c2c2c;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

// NEON --
// $primary-neon: #cd4dff;
// $primary-neon-tint: #ce63f8;
// $primary-neon-shade: #a63fcf;

// $secondary-neon: #00fffb;
// $secondary-neon-tint: #65fffc;
// $secondary-neon-shade: #01d0cd;

// $tertiary-neon: #ff8585;
// $tertiary-neon-tint: #ffa1a1;
// $tertiary-neon-shade: #c46767;

// $warn-neon: #ffc107;
// $warn-neon-tint: #fff350;
// $warn-neon-shade: #ffc400;

// $danger-neon: #ff1744;
// $danger-neon-tint: #ff616f;
// $danger-neon-shade: #c4001d;

// $success-neon: #00bfa5;
// $success-neon-tint: #5df296;
// $success-neon-shade: #008e76;

// body[data-theme-color='neon'],
// .ios body[data-theme-color='neon'] {

//   --ion-background-color: #232323;
//   --ion-background-color-rgb: 255, 255, 255;
//   --ion-item-background: #232323;
//   --ion-toolbar-background: #232323;
//   --ion-tab-bar-background: #232323;
//   --ion-card-background: #232323;

//   /** primary **/
//   --ion-color-primary: #{$primary-neon};
//   --ion-color-primary-rgb: 124, 77, 255;
//   --ion-color-primary-contrast: #ffffff;
//   --ion-color-primary-contrast-rgb: 255, 255, 255;
//   --ion-color-primary-shade: #{$primary-shade};
//   --ion-color-primary-tint: #{$primary-tint};

//   /** secondary **/
//   --ion-color-secondary: #{$secondary-neon};
//   --ion-color-secondary-rgb: 2, 136, 209;
//   --ion-color-secondary-contrast: #ffffff;
//   --ion-color-secondary-contrast-rgb: 255, 255, 255;
//   --ion-color-secondary-shade: #{$secondary-neon-shade};
//   --ion-color-secondary-tint: #{$secondary-neon-tint};

//   /** tertiary **/
//   --ion-color-tertiary: #{$tertiary};
//   --ion-color-tertiary-rgb: 255, 138, 128;
//   --ion-color-tertiary-contrast: #ffffff;
//   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//   --ion-color-tertiary-shade: #{$tertiary-neon-shade};
//   --ion-color-tertiary-tint: #{$tertiary-neon-tint};

//   /** success **/
//   --ion-color-success: #{$success-neon};
//   --ion-color-success-rgb: 0, 191, 165;
//   --ion-color-success-contrast: #ffffff;
//   --ion-color-success-contrast-rgb: 255, 255, 255;
//   --ion-color-success-shade: #{$success-neon-shade};
//   --ion-color-success-tint: #{$success-neon-tint};

//   /** warning **/
//   --ion-color-warning: #{$warn-neon};
//   --ion-color-warning-rgb: 255, 193, 7;
//   --ion-color-warning-contrast: #000000;
//   --ion-color-warning-contrast-rgb: 0, 0, 0;
//   --ion-color-warning-shade: #{$warn-neon-shade};
//   --ion-color-warning-tint: #{$warn-neon-tint};

//   /** danger **/
//   --ion-color-danger: #{$danger};
//   --ion-color-danger-rgb: 255, 23, 68;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255, 255, 255;
//   --ion-color-danger-shade: #{$danger-neon-shade};
//   --ion-color-danger-tint: #{$danger-neon-tint};
// }
